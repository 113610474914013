@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Kanit", sans-serif !important;
  line-height: 1.3;
  color: #ebebeb;
  font-weight: normal;
}

input:focus {
  outline: none !important;
}

input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #fff !important;
}

input::placeholder,
textarea::placeholder {
  color: #ffffffb9 !important;
}

.ant-input-outlined:focus,
.ant-input-outlined:hover,
.ant-input-outlined:focus-within {
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent;
}
.ant-select-selector :focus,
.ant-select-selector :focus-within {
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent;
}


.ant-input-outlined,
.ant-input-outlined.ant-input-status-error {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
  background-image: url(../src/assets/img/input_bg.png) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-color: transparent !important;
  height: 46px;
  border-radius: 0;
  padding: 0 20px;
  color: #fff;
}

.ant-form-item-label{
  padding-bottom: 5px !important;


}
.ant-form-item-label label{
  font-size: 11px !important;
}

.swap-point-wallet{
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
  background-image: url(../src/assets/img/input-swap.png) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-color: transparent !important;
  height: 50px;
  border-radius: 0;
  color: #fff;
}

.ton-coin-wallet-swap img{
  width: 54px;
  height: 48px;

}

.swap{
  background-image: url(../src/assets/img/button-swap.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 120px;
  height: 40px;
}


.invite-friend{
  background-image: url(../src/assets/img/invite-friends.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 60px;
}

a {
  cursor: pointer;
  transition: none;
}

img {
  width: 100%;
}

body {
  margin: 0 !important;
  color: #35373F;
  background-image: url(../src/assets/img/background.png);
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
}

.bg-primary-gradient {
  background: linear-gradient(270deg, #00a2e8, #53e3df);
}
.bg-secondary-gradient {
  background: linear-gradient(270deg, #ff614b, #fd5065);
}

/* Trending */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.slide {
  height: 100%;
  transition: 300ms all linear;
}

.connect-btn-condition .wallet-adapter-dropdown {
  width: 100%;
}

.ant-layout {
  background: transparent;
  
  max-width: 400px;
  margin: 0 auto;
}
.ant-layout-content {
  
  width: 100%;
  margin: auto;
  padding-bottom: 100px;
  padding-right: 80px;
  padding-top: 80px;
  padding-left: 15px;
}


.ant-layout-header {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #000000;
  background-repeat: no-repeat !important;
  background-size: cover  !important;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-btn {
  padding: 0;
  margin-left: -20px;
}

.ant-menu.menu-main .ant-menu-item:first-child.ant-menu-item-selected {
  background: none !important;
}

.ant-menu.menu-main .ant-menu-item {
  display: flex;
  align-items: center;
  margin-block: 0;
  padding: 0 !important;
}

.ant-menu-inline.menu-main .ant-menu-item,
.ant-menu-vertical.menu-main .ant-menu-item,
.ant-menu-inline.menu-main .ant-menu-submenu-title,
.ant-menu-vertical.menu-main .ant-menu-submenu-title {
  margin-inline: 0;
  width: 100%;
  border-radius: 0;
  color: #ffffff69;
}

.ant-menu-inline.menu-main .ant-menu-item path{
  fill: #ffffff69;
}

.ant-menu-inline.menu-main .ant-menu-item span{
  margin-inline-start: 0;
  color: #ffffff69;
}

.ant-menu-dark.menu-main .ant-menu-item-selected ,
.ant-menu-dark .menu-main > .ant-menu-item-selected ,
.ant-menu-dark.menu-main .ant-menu-item:hover  {
  background: #181818;
  color: #fff !important;
}
.ant-menu-dark.menu-main .ant-menu-item-selected span,
.ant-menu-dark .menu-main > .ant-menu-item-selected span,
.ant-menu-dark.menu-main .ant-menu-item:hover span {
  color: #fff !important;
}

.ant-menu-dark.menu-main .ant-menu-item-selected path,
.ant-menu-dark.menu-main .ant-menu-item:hover path{
  fill: #fff !important;
}


a:hover {
  color: inherit;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-picker.ant-picker-outlined {
  border: 0 !important;
}

/* @media screen and (max-width: 768px) {
  .wallet-adapter-button-trigger {
    border-radius: 10px !important;
    height: 40px !important;
    line-height: 40px;
    padding: 0 15px !important;
    font-size: 14px !important;
  }
  .slider {
    width: 100%;
  }
  .slide-track {
    gap: 10px;
  }

  .ant-layout .ant-layout-header,
  .ant-layout.ant-layout-footer {
    background: #101010 !important;
  }

  tc-root[data-tc-dropdown-container="true"]{
    left: auto !important;
    right: 20px !important;
  }
} */
.ton-amount-button button, .ton-amount-button .button{
  background-color: transparent;
  height: 44px;
  line-height: 44px;
  padding: 0 5px 0 40px;
  justify-content: center;
  width: 175px;
  border-radius: 0;
  background-image: url(../src/assets/img/amount_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex
}

.ton-connect-button .button{
  background-color: transparent;
  height: 44px;
  padding: 0 5px 0 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 175px;
  border-radius: 0;
  background-image: url(../src/assets/img/wallet_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-transform: uppercase;
}

.ton-connect-button button{
  width: 100%;
  background-color: transparent;
  box-shadow: none !important
}
.ton-connect-button button svg{
  fill: #FFF;
}

.ton-connect-button button [data-tc-text="true"]{
  color: #FFF ;
  font-size: 13px;
  font-weight: normal;
}



.ant-layout .ant-layout-sider{
  background-color: transparent !important;
  position: absolute;
  right: 15px;
  top: 15px;
  padding-top: 65px;
}
.menu-main .ant-menu-item{
  height: 56px;
}

.menu-main .ant-menu-item a{
  width: 56px;
  height: 56px;
  background-image: url(../src/assets/img/menu_home.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.menu-main .ant-menu-item a.active{
  background-image: url(../src/assets/img/menu_home_active.png);
}

.menu-main .ant-menu-item.menu-badge a{
  background-image: url(../src/assets/img/menu_badge.png);
}
.menu-main .ant-menu-item.menu-badge a.active{
  background-image: url(../src/assets/img/menu_badge_active.png);
}

.menu-main .ant-menu-item.menu-maps a{
  background-image: url(../src/assets/img/menu_task.png);
}
.menu-main .ant-menu-item.menu-maps a.active{
  background-image: url(../src/assets/img/menu_task_active.png);
}

.menu-main .ant-menu-item.menu-leaderboard a{
  background-image: url(../src/assets/img/menu_leaderboard.png);
}
.menu-main .ant-menu-item.menu-leaderboard a.active{
  background-image: url(../src/assets/img/menu_leaderboard_active.png);
}

.menu-main .ant-menu-item.menu-market a{
  background-image: url(../src/assets/img/menu_market.png);
}
.menu-main .ant-menu-item.menu-market a.active{
  background-image: url(../src/assets/img/menu_market_active.png);
}

.menu-main .ant-menu-item.menu-newquest a{
  background-image: url(../src/assets/img/menu_newquest.png);
}
.menu-main .ant-menu-item.menu-newquest a.active{
  background-image: url(../src/assets/img/menu_newquest_active.png);
}

.menu-main .ant-menu-item.menu-wallet a{
  background-image: url(../src/assets/img/menu_setting.png);
}
.menu-main .ant-menu-item.menu-wallet a.active{
  background-image: url(../src/assets/img/menu_setting_active.png);
}
.game-slots{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 10px;
  max-width: 400px;
  margin: auto;
}


.game-slots .item{
  flex: 1;
}
.game-slots .item .inner{
  position: relative;
  padding-top: 108.5227272727273%;
}



.game-slots .item .inner .link{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url(../src/assets/img/slot_unlock.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.game-slots .item .number{
  position: absolute;
  top: 3px;
  left: 19px;
  z-index: 9;
  color: #879199;
  font-weight: bold;
}
.game-slots .item .inner img{
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  margin-right: 17px;
  margin-top: 6px;
}
.game-slots .item .energy{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  z-index: 9;
  text-align: center;
  padding-left: 15px;
  font-size: 16px;
}
.game-slots .item.active .inner .link{
  background-image: url(../src/assets/img/slot_active.png);
}

.game-slots:after, .game-slots::before{
  content: "";
  width: 52px;
  height: 60px;
  background-image: url(../src/assets/img/left_border.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  bottom: -10px;
  z-index: 1;
}
.game-slots:after{
  right: -10px;
  background-image: url(../src/assets/img/right_border.png);
}
.game-slots:before{
  left: -10px;
  
}

.detail{
  height: calc(100vh - 200px);
  overflow: hidden;
  overflow-y: scroll;
}
.char-frame{

}
.char-frame-inner, .char{
  padding-top: 115.0170648464164%;
  position: relative;
}

.char-frame-content, .char-content{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 12%;

  background-image: url(../src/assets/img/frame.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.char-content{
  background: transparent;
  padding: 0;
}

.char-frame .char-head .name, .char-content .char-head .name{
  flex: 1;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  font-size: 18px;
  text-shadow: 2px 2px 0px black;
}
.char-frame .energy, .char-content .energy{
  background-image: url(../src/assets/img/energy.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 28px;
  width: 115px;
  position: relative;
}

.char-frame .energy .fillwrp, .char-content .energy .fillwrp{
  position: absolute;
  top: 6px;
  bottom: 8px;
  left: 27px;
  right: 3px;
}
.char-frame .energy .fill, .char-content .energy .fill{
  height: 100%;
  border-radius: 2px;
  background-color: #15ABFF;
  display: block;
}

.char-frame .lucky, .char-content .lucky{
  background-image: url(../src/assets/img/lucky.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 25px;
  width: 115px;
  position: relative;
}


.char-frame .lucky .fillwrp, .char-content .lucky .fillwrp{
  position: absolute;
  top: 5px;
  bottom: 7px;
  left: 27px;
  right: 3px;
}
.char-frame .lucky .fill, .char-content .lucky .fill{
  display: block;
  height: 100%;
  border-radius: 2px;
  background-color: #17A900;
}

.char-frame .char-img, .char-content .char-img{
  width: 140px;
  margin: 10px auto;
}

.char-content .char-img{
  width: 130px;
}

.modal-profile{

}

.subhead{
  padding: 15px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9999;
  max-width: 400px;
  margin: auto;
}

.ant-modal-content{
  background-image: url(../src/assets/img/frame.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  background-color: transparent !important;
  min-height: 425px;
  border-radius: 0 !important;
  padding: 40px 35px !important; 
  box-shadow: none !important;
}
.ant-modal-mask{
  backdrop-filter: blur(5px);
}
.ant-modal-root .ant-modal{
  max-width: calc(100vw - 55px);
}
.address{
  height: 40px;
  /* background-image: url(../../public/images/modal_profile_address.png); */
  background-size: 100% 100%;
  display: flex;
  color: #000;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
}

.address .copy{
  /* background-image: url(../../public/images/modal_profile_address_copy.png); */
  width: 25px;
  height: 25px;
  background-size: contain;
  background-color: transparent;
  border: none;
  cursor: pointer;
}


.btn-common{
  background-color: transparent;
  height: 44px;
  padding: 0 5px;
  justify-content: center;
  width: 126px;
  border-radius: 0;
  background-image: url(../src/assets/img/btn-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.amount-bg{
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background-image: url(../src/assets/img/amount_t_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.amount-bg.point{
  background-image: url(../src/assets/img/amount_l_bg.png);
}

.frame-2{
  background-image: url(../src/assets/img/frame2.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: calc(100vh - 220px);
  max-height: 500px;
  margin-left: -5px;
  margin-right: -5px;
  padding: 40px 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* overflow: hidden; */
  padding-bottom: 0;
}

.frame-2:after{
  content: "";
  background-image: url(../src/assets/img/frame2_foot.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  display: block;
  height: 25px;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0px;
  margin-left: 0;
  margin-right: 0;
}

.frame-2-inner{
  height: 100%;
  overflow: hidden;
}

.frame-2-inner .head{
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
}

.frame-3{
  background-image: url(../src/assets/img/frame-3.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: calc(100vh - 220px);
  max-height: 500px;
  margin-left: -5px;
  margin-right: -5px;
  padding: 40px 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* overflow: hidden; */
  padding-bottom: 0;
}

.frame-3:after{
  content: "";
  background-image: url(../src/assets/img/frame-3-0.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  display: block;
  height: 25px;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0px;
  margin-left: 0;
  margin-right: 0;
}

.frame-4{
  background-image: url(../src/assets/img/frame-3.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: calc(100vh - 220px);
  max-height: 500px;
  margin-left: -5px;
  margin-right: -5px;
  padding: 40px 38px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* overflow: hidden; */
  padding-bottom: 0;
}

.frame-4:after{
  content: "";
  background-image: url(../src/assets/img/frame-3-0.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  display: block;
  height: 25px;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0px;
  margin-left: 0;
  margin-right: 0;
}

.frame-4-inner{
  height: 100%;
  overflow: hidden;
  padding-top: 30px;
}

.frame-4-inner .head{
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
}



.sprite-item{

}

.sprite-item-inner{
  padding-top: 124.1666666666667%;
  position: relative;
  background-image: url(../src/assets/img/sprite_item_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.sprite-item .img{
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: auto;
}

.sprite-item .img img{
  width: 55px;
  height: 55px;
  object-fit: contain;
  margin: 0px auto;
}

.sprite-item .energy{
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  padding-left: 10px;
  font-weight: normal;
  font-size: 17px;
}

.market .sprite-item-inner{
  background-image: url(../src/assets/img/market_sprite_bg.png);
}

.market .sprite-item .energy{
  bottom: 7px;
}

.items-item{

}
.items-item-inner{
  padding-top: 124.1666666666667%;
  position: relative;
  background-image: url(../src/assets/img/sprite_item2_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.market .items-item-inner{
  background-image: url(../src/assets/img/market_item_bg.png);
}

.items-item .img{
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: auto;
}

.items-item .img img{
  width: 36px;
  height: 36px;
  object-fit: contain;
  margin: 10px auto;
}

.items-item .quantity{
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-weight: normal;
  font-size: 17px;
}

.market .items-item .quantity{
  bottom: 7px;
  left: 8px;
}

.badge-tab{
  margin-top: 28px;
  margin-bottom: 24px;
}
.badge-tab-item{
  background-image: url(../src/assets/img/badge_tab_items_o.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 110px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}

.badge-tab-item.current{
  background-image: url(../src/assets/img/badge_tab_items.png);
}
.market .badge-tab{
  margin-top: 12px;
  margin-bottom: 10px;
}

.market-tab-item{
  background-image: url(../src/assets/img/market-items-0.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 120px;
  height: 45px;
  line-height: 45px;
  text-align: center;

}

.market-tab-item.current{
  background-image: url(../src/assets/img/market-items.png);
  margin-top: 10px;
}

.sprite-list, .frame-2-content{
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 200px;
}


::-webkit-scrollbar {
  display: none;
}


.maps-list{
  height: calc(100vh - 200px);
  overflow: hidden;
  overflow-y: scroll;
}
.maps-list-item{
  background-image: url(../src/assets/img/map_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 61.37787056367432%;
  position: relative;
  margin-bottom: 5px;
}

.maps-list-item.coming{
  pointer-events: none;
  /* opacity: 0.5; */
  filter: grayscale(0.7);
}

.maps-list-item .inner{
  position: absolute;
  top: 35px;
  left: 20px;
  right: 20px;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.maps-list-item .coming-text{
  height: 100%;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
}

.maps-list-item .name{
  position: absolute;
  left: 0;
  right: 0;
  top: 6px;
  text-transform: uppercase;
  text-align: center;
}
.maps-list-item .content p, .maps-list-item .content span{
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
}

.maps-list-item .reward-list .item{
  width: 28px;
}


.go-hunt{
  background-image: url(../src/assets/img/hunt.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 115px;
  height: 34px;
  margin-top: 10px;
}
.buy-market{
  background-image: url(../src/assets/img/buy.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 74px;
  height: 30px;
  line-height: 30px;
  margin: 3px auto auto;
}

.go{
  background-image: url(../src/assets/img/go.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 70px;
  height: 25px;
}

.leaderboard-wrp{
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.leaderboard .summary{
  background-image: url(../src/assets/img/leaderboard-sum.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 70px;
  padding: 15px 20px;
  
  text-transform: uppercase;
  font-size: 11px;
}
.leaderboard .summary p{
  line-height: 1.2;
}

.leaderboard .frame-2{
  padding: 65px 25px 0px;
}


.wallet .frame-2{
  /* padding: 35px 30px 0px; */
}


.notify-box{
  background-image: url(../src/assets/img/notify.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 61.37787056367432%;
  position: relative;
  margin-bottom: 5px;
  margin-top: 15px;
}

.notify-box .title{
  position: absolute;
  left: 0;
  right: 0;
  top: 6px;
  text-transform: uppercase;
  text-align: center;
}
.notify-box .inner{
  position: absolute;
  top: 35px;
  left: 20px;
  right: 20px;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.socials .item{
  width: 40px;
}

.coin{
  display: flex;
  align-items: center;
  line-height: 1;
}
.coin img{
  width: 22px;
  margin-left: 8px;
}

.ton-coin-quest img{
  width: 40px;
}

.leaderboards {
  background: #7F4800;
  border-radius: 10px;
  border: 2px solid #562f0042;
  box-shadow: 2px 2px 0px 0px #E69F01;
  padding: 15px 20px;
  margin-top: 0;
}

.leaderboards .item {
  margin-bottom: 25px;
}
.leaderboards .item .coin img{
  width: 18px;
}

.leaderboards .item .number img{
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.newquest {

  box-shadow: 2px 2px 0px 0px #E69F01;
  padding-top: 15px;
  margin-top: 0;
}



.newquest .item {
  margin-top: 15px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-image: url(../src/assets/img/frame-3-2.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.newquest .item .align-items{
  align-items: center;
}

.btn{
  width: 152px;
  height: 44px;
  background-image: url(../src/assets/img/button_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.btn.secondary{
  background-image: url(../src/assets/img/button2_bg.png);
}

.btn.is-small{
  width: 110px;
  height: 34px;
  line-height: 34px;
}

.txns-list{
  height: calc(100% - 80px);
  overflow: hidden;
  overflow-y: scroll;
  margin-top: -10px;
}


.ant-table-cell{
  background: #7F4800 !important;
  color: #fff !important;
  padding: 7px 10px !important;
  font-size: 12px;
  border-bottom: 1px solid #562f00 !important;
}
.ant-table-cell::before{
  content: none !important;
}

.ant-pagination{
  background: #562f00;
  margin: 0 !important;
  padding: 5px;
}
.ant-pagination .ant-pagination-item a{
  line-height: 30px;
}
.ant-pagination .ant-pagination-item-active{
  border-color: transparent !important
}

.modal-char .ant-modal-close{
  display: none;
}

.home-wrp{
  height: calc(100vh - 200px);
    overflow: hidden;
    overflow-y: scroll;
}

.ant-modal-close{
  width: 32px !important;
  height: 32px !important;
  background-image: url(../src/assets/img/close.png) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.ant-modal-close-x{
  display: none !important;
}

.unlocking{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}